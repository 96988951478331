.body{
    margin: 0;
    overflow: hidden;
}

.drawing{
    width: 100vw;
    height: 100vh;
    position: relative;
    display: block;
    background-color: var(--appBackgroundColor);
}

/* -- Loading svg text -- */
.svg_body {
        height: 100vh; /* Full screen height */
        margin: 0; /* Remove default browser margins */
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        padding: 0 2rem; /* Padding on left and right */
        background: var(--appBackgroundColor);
        box-sizing: border-box; /* Ensure padding is included in the size */
      }

.svg {
width: 100%; /* Full width of the section */
max-width: calc(100vw - 4rem); /* Account for left and right padding */
height: 100%; /* Full height of the section */
}

.svg_text {
font-family: "Mrs Saint Delafield", cursive;
letter-spacing: 15px;
stroke: var(--primaryColor);
font-size: clamp(calc(var(--h1FontSizeMobile) + 40px), 25vw, calc(var(--h1FontSizeDesktop) + 90px));
font-weight: var(--SVGLoaderFonteight);
stroke-width: 1;

animation: textAnimate 3s infinite alternate;
}

@keyframes textAnimate {
0% {
        stroke-dasharray: 0 50%;
        stroke-dashoffset: 20%;
        fill: var(--SVGLoaderImgImageColor);
}

100% {
        stroke-dasharray: 50% 0;
        stroke-dashoffset: -20%;
        fill: var(--SVGLoaderImgImageColor);
}
}



/* -- Loading dots --*/
.loadingDot {
    border-radius:100%;
    height:var(--dotSizes);
    width:var(--dotSizes);
    background-color: var(--secondaryColor);
    position:absolute;
    left:0;
    right:0;
    margin:auto;
    top:0;
    bottom:0;

    box-shadow: 0px var(--dotExpansion) 0px 0px #ffffff ,0px calc(var(--dotExpansion) * -1) 0px 0px #ffffff
    ,var(--dotExpansion) 0px 0px 0px #ffffff ,calc(var(--dotExpansion) * -1) 0px 0px 0px #ffffff ,
    var(--dotExpansion) var(--dotExpansion) 0px 0px #ffffff ,calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px #ffffff
    ,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px #ffffff ,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px #ffffff;
    -webkit-animation: load 3s infinite linear;
    -moz-animation: load 3s infinite linear;
    -o-animation: load 3s infinite linear;
    animation:load 3s infinite linear;

}


@-webkit-keyframes load {
    0%   {
        -webkit-transform: translate(0px,0px),rotate(0deg);
        box-shadow: 0px var(--dotExpansion) 0px 0px var(--primaryColor)
        ,0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
        ,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
        ,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
        , var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
        ,calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
        ,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
        ,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor);
    }

    25%{
        box-shadow: 0px var(--dotExpansion) 0px 0px var(--primaryColor)
,0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,
        calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
;
    }

    50%{
        box-shadow: 0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,0px var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,
        calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
;
    }

    70%{
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
    }

    75%{
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
        height:10px;
        width:10px;
    }

    80%{
        -webkit-transform: translate(0px,0px) rotate(360deg);
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
        background-color: var(--primaryColor)
;
        height:var(--dotExpansion);
        width:var(--dotExpansion);
    }

    85%{
        -webkit-transform: translate(0px,0px) rotate(360deg);
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
        background-color: var(--primaryColor)
;
        height:var(--dotExpansion);
        width:var(--dotExpansion);
    }

    90%{
        -webkit-transform: translate(0px,0px) rotate(360deg);
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
        background-color: var(--primaryColor)
;
        height:10px;
        width:10px;
    }

95%{
    box-shadow: 0px var(--dotExpansion) 0px 0px var(--primaryColor)
,0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,
    var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
;
}

100%{
    -webkit-transform: rotate(360deg);
    box-shadow: 0px var(--dotExpansion) 0px 0px var(--primaryColor)
,0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,
    var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
;
}

}

@-moz-keyframes load {
    0%   {
        -moz-transform: translate(0px,0px),rotate(0deg);
        box-shadow: 0px var(--dotExpansion) 0px 0px var(--primaryColor)
,0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,
        var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
;
    }

    25%{
        box-shadow: 0px var(--dotExpansion) 0px 0px var(--primaryColor)
,0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,
        calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
;
    }

    50%{
        box-shadow: 0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,0px var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,
        calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
;
    }

    70%{
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
    }

    75%{
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
        height:10px;
        width:10px;
    }

    80%{
        -moz-transform: translate(0px,0px) rotate(360deg);
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
        background-color: var(--primaryColor)
;
        height:var(--dotExpansion);
        width:var(--dotExpansion);
    }

    85%{
        -moz-transform: translate(0px,0px) rotate(360deg);
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
        background-color: var(--primaryColor)
;
        height:var(--dotExpansion);
        width:var(--dotExpansion);
    }

    90%{
        -moz-transform: translate(0px,0px) rotate(360deg);
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
        background-color: var(--primaryColor)
;
        height:10px;
        width:10px;
    }

    95%{
        box-shadow: 0px var(--dotExpansion) 0px 0px var(--primaryColor)
,0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,
        var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
;
    }

    100%{
        -moz-transform: rotate(360deg);
        box-shadow: 0px var(--dotExpansion) 0px 0px var(--primaryColor)
,0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,
        var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
;
    }

}

@keyframes load {
    0%   {
        transform: translate(0px,0px),rotate(0deg);
        box-shadow: 0px var(--dotExpansion) 0px 0px var(--primaryColor)
,0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,
        var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
;
    }

    25%{
        box-shadow: 0px var(--dotExpansion) 0px 0px var(--primaryColor)
,0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,
        calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
;
    }

    50%{
        box-shadow: 0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,0px var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,
        calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
;
    }

    70%{
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
    }

    75%{
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
        height:10px;
        width:10px;
    }

    80%{
        transform: translate(0px,0px) rotate(360deg);
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
        background-color: var(--primaryColor)
;
        height:var(--dotExpansion);
        width:var(--dotExpansion);
    }

    85%{
        transform: translate(0px,0px) rotate(360deg);
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
        background-color: var(--primaryColor)
;
        height:var(--dotExpansion);
        width:var(--dotExpansion);
    }

    90%{
        transform: translate(0px,0px) rotate(360deg);
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
        background-color: var(--primaryColor)
;
        height:10px;
        width:10px;
    }

    95%{
        box-shadow: 0px var(--dotExpansion) 0px 0px var(--primaryColor)
,0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,
        var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
;
    }

    100%{
        transform: rotate(360deg);
        box-shadow: 0px var(--dotExpansion) 0px 0px var(--primaryColor)
,0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,
        var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
;
    }

}
@-o-keyframes load {
    0%   {
        -o-transforms: translate(0px,0px),rotate(0deg);
        box-shadow: 0px var(--dotExpansion) 0px 0px var(--primaryColor)
,0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,
        var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
;
    }

    25%{
        box-shadow: 0px var(--dotExpansion) 0px 0px var(--primaryColor)
,0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,
        calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
;
    }

    50%{
        box-shadow: 0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,0px var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,
        calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
;
    }

    70%{
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
    }

    75%{
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
        height:10px;
        width:10px;
    }

    80%{
        -o-transforms: translate(0px,0px) rotate(360deg);
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
        background-color: var(--primaryColor)
;
        height:var(--dotExpansion);
        width:var(--dotExpansion);
    }

    85%{
        -o-transforms: translate(0px,0px) rotate(360deg);
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
        background-color: var(--primaryColor)
;
        height:var(--dotExpansion);
        width:var(--dotExpansion);
    }

    90%{
        -o-transforms: translate(0px,0px) rotate(360deg);
        box-shadow: 0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,
        0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
,0px 0px 0px 0px var(--primaryColor)
;
        background-color: var(--primaryColor)
;
        height:10px;
        width:10px;
    }

    95%{
        box-shadow: 0px var(--dotExpansion) 0px 0px var(--primaryColor)
,0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,
        var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
;
    }

    100%{
        -o-transforms: rotate(360deg);
        box-shadow: 0px var(--dotExpansion) 0px 0px var(--primaryColor)
,0px calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) 0px 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) 0px 0px 0px var(--primaryColor)
,
        var(--dotExpansion) var(--dotExpansion) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,var(--dotExpansion) calc(var(--dotExpansion) * -1) 0px 0px var(--primaryColor)
,calc(var(--dotExpansion) * -1) var(--dotExpansion) 0px 0px var(--primaryColor)
;
    }

}