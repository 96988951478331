:root{
  --headerWitdh : 32%;

  --mainBorderRadius : 5px;
  --secondaryBorderRadius : 10px;

  --borderWidth : 3px;

  --maxLogosWitdh : 55px;
  --maxLanguageImageWidth : 35px;

  --bodyHeaderLenght : 100%;

  --h3Margin: 5px 0 0 0;

  --timelineDotSize : 25px;

  --timelineDotMarginLeft : 7px;


   /* General Variables */
    --h1FontSize: 35px;
    --h2FontSize: 23px;
    --h3FontSize: 21px;
    --h4FontSize: 21px;
    --h5FontSize: 15px;
    --h6FontSize: 19px;
    --h7FontSize: 18px;
    --pFontSize : 13px;


    --h1FontSizeMobile : 55px;
    --h2FontSizeMobile: 23px;
    --h3FontSizeMobile: 21px;
    --h4FontSizeMobile : 20px;
    --h5FontSizeMobile : 18px;
    --h6FontSizeMobile : 17px;
    --h7FontSizeMobile : 19px;
    --pFontSizeMobile : 16px;
    --buttonFontSizeMobile : 21px;

    --h1FontSizeDesktop : 80px;
    --h2FontSizeDesktop : 26px;
    --h3FontSizeDesktop : 24px;
    --h4FontSizeDesktop : 22px;
    --h5FontSizeDesktop : 20px;
    --h6FontSizeDesktop : 18px;
    --h7FontSizeDesktop : 23px;
    --pFontSizeDesktop : 19px;
    --buttonFontSizeDesktop : 23px;
 
}
[theme = "dark"]{
  --appBackgroundColor: rgb(43, 42, 44);
  --contentBackgroundColor: rgb(59, 59, 62,  0.5);
  --innerBackgroundColor: rgb(80, 79, 84, 0.5);
  --otherBackgroundColor: #504f54;

  --contentBorderColor : transparent;
  --headerBorderColor : rgb(80, 79, 84, 0.5);
  

  --primaryColor : rgb(101, 136, 100);
  --secondaryColor : rgb(224, 222, 222);
  --terziaryColor : rgb(213, 213, 213);

  --dotBoxShadowColor :  rgb(220, 220, 220);
  --SVGLoaderImgImageColor : #5b5f5c;;

  /*
  Colors used in the template
  --appBackgroundColor : #111111;
  --contentBackgroundColor : #1E1E1F;
  --innerBackgroundColor: #373738;
  --otherBackgroundColor : #282829;
  --contentBorderColor : #414040;
  --primaryColor : #fdd66d;
  --secondaryColor : #ffffff;
  --terziaryColor : #d5d5d5;
 */

}

[theme = "light"]{
  /* --appBackgroundColor: #eeeeee; 
  --contentBackgroundColor: #d6d6d6;
  --innerBackgroundColor: #b8b8b8;
  --otherBackgroundColor: #b8b8b8;*/
  --appBackgroundColor: rgb(212, 212, 212); 
  --contentBackgroundColor: rgb(184, 184, 184, 0.3);
  --innerBackgroundColor: rgb(184, 184, 184);
  --otherBackgroundColor: rgb(184, 184, 184);

  --contentBorderColor : transparent;
  --headerBorderColor : rgb(80, 79, 84, 0.5);


  --primaryColor : rgb(101, 136, 100);
  --secondaryColor : rgb(72, 72, 72);
  --terziaryColor : rgb(123, 123, 123);

  --dotBoxShadowColor :   rgb(184, 184, 184);
  --SVGLoaderImgImageColor : rgb(72, 72, 72);




  /*
  Colors used in the template
  --appBackgroundColor : #DDDDDD;
  --contentBackgroundColor : #eeeeee;
  --innerBackgroundColor: #cdcdcd;
  --otherBackgroundColor : #cdcdcd;
  --contentBorderColor : #292929;
  --primaryColor : #658864;
  --secondaryColor : #525252;
  --terziaryColor : #7b7b7b;
  */
}

[devicetype = "Desktop"]{
  /* Loading animation variables */
  --dotSizes : 70px;
  --dotExpansion : 225px;

  /* app  variables */
  --mainWrapperMargins : 1% 7% 2% 3%;

  /* header variables */
  --headerTitleMargins : 10px 0 0 0;
  --headerSocialIconsMargins:  0;  

  /* Portfolio section variables */
  --portoflioGridLayour : 50% 50%;
  --RepoCardMaxWitdh : 800px;
  --bodyGgridPadding : 3%;


  /* About section variables */
  --langugeGridTemplateColumn : 15% 85%;
  --itSkillsGridTempleteColumn : auto auto auto;

  --verticalTimelineContentWidth : 97%;
  --verticalTimelineArrowTop : 28px;
  --verticalTimelineContentMarginLeft : 57px;

  /* Header section variables */
  --headerImgShadow : 0  0  1px 1px var(--secondaryColor);
  --headerImgMargin : 20px 0 20px 0;

  /* Icons*/
  --maxLogosWitdh : 32px;
  --HeaderIconsMargin : 5% 5% 5% 0;

  --timelineDotSize : 35px;
  --timelineDotMarginLeft : 2px;

  /* Margins */
  --h3Margin: 10px 0 0 0;

  /*Loader*/
  --SVGLoaderFonteight : 800;
  --SVGLoaderStrokeWidth : 4;

}

[devicetype = "Tablet"]{
  /* Loading animation variables */
  --dotSizes : 30px;
  --dotExpansion : 100px;

  /* app  variables */
  --mainWrapperMargins : 1% 2%;

  /* header variables */
  --headerTitleMargins : 5px 0 0 0;
  --headerSocialIconsMargins:  0 0 0 0;  

  /* Portfolio section variables */
  --portoflioGridLayour : 100%;
  --RepoCardMaxWitdh : 800px;
  --bodyGgridPadding : 3% 0;

  /* About section variables */
  --langugeGridTemplateColumn : 17% 83%;
  --itSkillsGridTempleteColumn : auto auto auto;

  --verticalTimelineContentWidth : 97%;
  --verticalTimelineArrowTop : 25px;
  --verticalTimelineContentMarginLeft : 52px;

  /* Header section variables */
  --headerImgShadow : 0  0  1px 1px var(--secondaryColor);
  --headerImgMargin : 20px 15px 20px 20px;

  /* Icons*/
  --maxLogosWitdh : 40px;
  --HeaderIconsMargin : 10px;

  --h1FontSize : 45px;
  --h2FontSize : 26px;
  --h3FontSize : 21px;
  --h4FontSize : 21px;
  --h5FontSize : 19px;
  --h6FontSize : 17px;
  --pFontSize : 15px;
  --buttonFontSize : 20px;
  
  --pFontSize : 19px;
  --buttonFontSize : 20px;

    /* Margins */
    --h3Margin: 5px 0 0 0;

    
  /*Loader*/
  --SVGLoaderFonteight : 800;
  --SVGLoaderStrokeWidth : 6;


}

[devicetype = "Mobile"]{
  /* Loading animation variables */
  --dotSizes : 30px;
  --dotExpansion : 100px;

  /* app  variables */
  --mainWrapperMargins : 0% 0%;

  /* header variables */
  --headerTitleMargins :  5px 5px  0 5px;
  --headerSocialIconsMargins:  0 0 0px 0px;

  /* Portfolio section variables */
  --portoflioGridLayour : 100%;
  --RepoCardMaxWitdh : 800px;
  --bodyGgridPadding : 3% 0;

  /* About section variables */
  --langugeGridTemplateColumn : 30% 70% ;
  --itSkillsGridTempleteColumn : auto auto;

  --verticalTimelineContentWidth : 86%;
  --verticalTimelineArrowTop : 25px;
  --verticalTimelineContentMarginLeft : 57px;


  /* Header section variables */
  --headerImgShadow : 0  0  1px 1px var(--secondaryColor);
  --headerImgMargin : 5px 0px 7px 1px;

  /* Icons*/
  --maxLogosWitdh : 30px;
  --HeaderIconsMargin : 5px;

    /* Margins */
    --h3Margin: 10px 0 0 1px;

    
  /*Loader*/
  --SVGLoaderFonteight : 800;
  --SVGLoaderStrokeWidth : 6;

}


.App {
  text-align: center;
  position: fixed;
  height: 100%;
  width: 100%;

  background-color: var(--appBackgroundColor);
  overflow-y: auto;
}

.App main{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
}

.main_wrapper_desktop{
  margin: var(--mainWrapperMargins);
  display: grid;
  grid-template-columns: var(--headerWitdh) calc(100% - var(--headerWitdh));
  grid-gap: 2rem;
}

.main_wrapper_mobile{
  margin: var(--mainWrapperMargins);
}

body {
  background-color: var(--appBackgroundColor);
  color: var(--primaryColor);
}

h1{
  font-size: clamp(var(--h1FontSizeMobile), 5vw, var(--h1FontSizeDesktop));
  color : var(--primaryColor);
  cursor: default;
}

h2{
  font-size: clamp(var(--h2FontSizeMobile), 2vw, var(--h2FontSizeDesktop));
  font-style: italic;

  color: var(--secondaryColor);
  cursor: default;
}

h3{
  font-size: clamp(var(--h3FontSizeMobile), 2.5vw, var(--h3FontSizeDesktop));
  font-style: italic;

  color: var(--secondaryColor);
  cursor: default;
}

h4{
  font-size: clamp(var(--h4FontSizeMobile), 2.5vw, var(--h4FontSizeDesktop));
  color: var(--secondaryColor);
  font-weight: normal;
  cursor: default;
}

h5{
  font-size: clamp(var(--h5FontSizeMobile), 2vw, var(--h5FontSizeDesktop));
  color: var(--secondaryColor);
  font-weight: normal;
  cursor: default;
}

h6{
  font-size: clamp(var(--h6FontSizeMobile), 2vw, var(--h6FontSizeDesktop));
  color: var(--secondaryColor);
  font-weight: normal;
  cursor: default;
}
/* 
h7{
  font-size: clamp(var(--h7FontSizeMobile), 2vw, var(--h7FontSizeDesktop));
  color: var(--secondaryColor);
  font-weight: normal;
  cursor: default;
} */

p{
  font-size: clamp(var(--pFontSizeMobile), 2vw, var(--pFontSizeDesktop)); 
  color: var(--secondaryColor);
  cursor: default;
}

li{
  font-size: clamp(calc(var(--pFontSizeMobile) + 2px) ,  2vw,calc(var(--pFontSizeDesktop) + 2px)); 
  color: var(--secondaryColor);
  cursor: default;
}

button{
  font-size: clamp(var(--buttonFontSizeMobile), 2vw, var(--buttonFontSizeDesktop));
}


